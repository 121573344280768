<template>
    <div class='anket-page'>
        <AppPageLoadingAnimation v-if="massagistInfo.loading" />
        <h3>Анекта</h3>
        <div class="page-block common-info" v-if="!massagistInfo.loading">
            <AppEditNameForm :name="massagistInfo.massagist.username" @sendNewName="massagistInfo.saveNewData($event)" />
            <AppEditAgeForm :age="massagistInfo.massagist.age" @sendNewAge="massagistInfo.saveNewData($event)" v-if="status !== 'saloon'" />
            <span class="title" v-if="status !== 'saloon'" >Пол</span>
            <AppEditSexForm @sendNewSex="massagistInfo.saveNewData($event)" :sex="massagistInfo.massagist.sex" v-if="status !== 'saloon'" />
            <AppEditCityForm @sendNewCity="massagistInfo.saveNewData($event)" :city="massagistInfo.massagist.city" />
            <AppEditPhoneForm @sendNewPhone="massagistInfo.saveNewData($event)" :phone="massagistInfo.massagist.phone" />
            <AppAddNumber @sendNewAdditionalNumber="massagistInfo.saveNewData($event)" :additionalNumber="massagistInfo.massagist.additional_phones" />
        </div>
        <AppClientInputs @sendNewClientTypes="massagistInfo.saveNewData($event)" :options="massagistInfo.massagist.massage_to" />

        <div class="page-block clients-age">
            <AppClientAgeFrom :age="+massagistInfo.massagist.client_age_from" @sendNewAge="massagistInfo.saveNewData($event)" />
            <AppClientAgeTill :age="+massagistInfo.massagist.client_age_till" @sendNewAge="massagistInfo.saveNewData($event)" />
        </div>

        <AppAboutMe
            @sendNewMassagistAboutData="massagistInfo.saveNewData($event)"
            @updateModeration="massagistInfo.saveNewData($event)"
            :text="massagistInfo.massagist.about_me"
            :approved="massagistInfo.massagist.about_me_approved" />

        <div class="page-block my-place">
             <AppMyPlaceInputForm 
                @myPlaceNewInfo="massagistInfo.saveNewData($event)"
                @sendNewPricePerHourToDB="massagistInfo.saveNewData($event)"
                @sendNewPricePerTwoHoursToDB="massagistInfo.saveNewData($event)"
                :checked="massagistInfo.massagist.my_place"
                :currentPrice="+massagistInfo.massagist.my_place_per_hour_price"
                :currentPricePerTwoHours="+massagistInfo.massagist.my_place_per_two_hours_price"
            />
        </div>
        <div class="page-block your-place" v-if="status !== 'saloon'" >
            <AppYourPlaceInputForm 
                @myPlaceNewInfo="massagistInfo.saveNewData($event)"
                @sendNewPricePerHourToDB="massagistInfo.saveNewData($event)"
                @sendNewPricePerTwoHoursToDB="massagistInfo.saveNewData($event)"
                :checked="massagistInfo.massagist.your_place"
                :currentPrice="+massagistInfo.massagist.your_place_per_hour_price"
                :currentPricePerTwoHours="+massagistInfo.massagist.your_place_per_two_hours_price"
            />
        </div>
        <div class="page-block massage-type">
            <AppMassageBasicTypes @sendNewMassageTypes="massagistInfo.saveNewData($event)" :options="massagistInfo.massagist.base_massage_types" />
            <AppMassageAddTypes @sendNewMassageTypes="massagistInfo.saveNewData($event)" :options="massagistInfo.massagist.additional_massage_types" />
        </div>
        <div class="page-block">
            <AppWorkingDays @sendWorkingDays="massagistInfo.saveNewData($event)" :options="massagistInfo.massagist.working_days" />
        </div>
        <div class="page-block">
            <AppWorkingTime @sendWorkingTime="massagistInfo.saveNewData($event)" :options="massagistInfo.massagist.working_hours" />
        </div>
    </div>
</template>

<script>
import AppBasicInput from "@/components/inputs/app-basic-input.vue";
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppTextArea from "@/components/inputs/app-text-area.vue";
import AppSelectInput from "@/components/inputs/app-select-input.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import MassageInputTypes from "@/database/massageInputTypes.js";
import MassageInputAdditional from "@/database/massageInputTypes.js";
import WorkingTime from "@/database/workingTime.js";

import AppEditNameForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-name-form.vue"
import AppEditAgeForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-age-form.vue"
import AppClientAgeFrom from "@/components/forms/massagist-edit-forms/app-massagist-edit-client-age-from.vue"
import AppClientAgeTill from "@/components/forms/massagist-edit-forms/app-massagist-edit-client-age-till.vue"
import AppAboutMe from "@/components/forms/massagist-edit-forms/app-massagist-edit-about-form.vue"
import AppMyPlaceInputForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-my-place.vue"
import AppYourPlaceInputForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-your-place.vue"
import AppEditSexForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-sex-input.vue"
import AppEditCityForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-city-form.vue"
import AppEditPhoneForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-phone-form.vue"
import AppAddNumber from "@/components/forms/massagist-edit-forms/app-massagist-edit-additional-num.vue"
import AppClientInputs from "@/components/forms/massagist-edit-forms/app-massagist-edit-client-input.vue"
import AppMassageBasicTypes from "@/components/forms/massagist-edit-forms/app-massagist-edit-massage-basic-types.vue"
import AppMassageAddTypes from "@/components/forms/massagist-edit-forms/app-massagist-edit-massage-add-types.vue"
import AppWorkingDays from "@/components/forms/massagist-edit-forms/app-massagist-edit-working-days.vue"
import AppWorkingTime from "@/components/forms/massagist-edit-forms/app-massagist-edit-working-time.vue"
import AppPageLoadingAnimation from "@/components/animations/app-page-loading-animation.vue";

import massagistApi from "@/api/massagist.js";

import { onMounted, ref, reactive, computed } from 'vue';
import { useRoute } from "vue-router";
import { useStore } from "vuex";

    export default {
        components: {
            AppBasicInput,
            AppCheckInput,
            AppTextArea,
            AppSelectInput,
            AppRegularButton,
            AppEditNameForm,
            AppEditAgeForm,
            AppEditSexForm,
            AppEditCityForm,
            AppEditPhoneForm,
            AppAddNumber,
            AppClientInputs,
            AppClientAgeFrom,
            AppClientAgeTill,
            AppAboutMe,
            AppMyPlaceInputForm,
            AppYourPlaceInputForm,
            AppMassageBasicTypes,
            AppMassageAddTypes,
            AppWorkingDays,
            AppWorkingTime,
            AppPageLoadingAnimation
        },
        setup(){
            const route = useRoute();
            const store = useStore();

            const status = computed(() => {
                let userStatus = store.state.user.status;
                return userStatus;
            })

            const inputs = {
                
                yourPlace: {
                    label: "Выезжаю к вам",
                    id: "yourPlace"
                },
                 oneHour: {
                    label: "Цена за час",
                    id: "oneHour",
                    inputType: "Number"
                },
                 twoHours: {
                    label: "Цена за 2 часа",
                    id: "twoHours",
                    inputType: "Number"
                },
            }
            const massageInputs = MassageInputTypes;
            const massageInputsAdditional = MassageInputAdditional;
            
            const workingTimeFrom = WorkingTime.workingTimeFrom;
            const workingTimeTill = WorkingTime.workingTimeTill;
            const phonesAmount = ref(0)
            const addPhoneField = () => {
                phonesAmount.value > 2 ? phonesAmount.value = 2 : phonesAmount.value++;
            }
            const closePhoneField = () => {
                phonesAmount.value = 0;
            }

            const massagistInfo = reactive({
                loading: false,
                massagist: {},
                saveNewData: async (data) => {
                    massagistInfo.loading = true;
                    await massagistApi.massagistUpdateData({ id: massagistInfo.massagist.id, token: store.state.user.token, column: data.column, value: data.value })
                        .then(response => console.log(response))
                        .catch(err => console.log(err));
                    massagistInfo.loading = false;
                    const massagist = await getMassagistInfo(massagistInfo.massagist.id)
                        .catch(err => console.log(err));
                        const isRememberedUser = JSON.parse(localStorage.getItem('user'));
                        if(isRememberedUser){
                            isRememberedUser.user = massagist;
                            localStorage.setItem('user', JSON.stringify(isRememberedUser));
                            store.dispatch('setUser', isRememberedUser); 
                        } else {
                            store.dispatch('setOnlyUserData', massagist)
                        }
                    console.log(data)
                },
                city: ""
            })


            const getMassagistInfo = async (id) => {
                massagistInfo.loading = true;
                const massagist = await massagistApi.getMassagistInfo({ id: id })
                                        .then(response => response.data)
                                        .catch(err => console.log(err));

                massagistInfo.massagist = massagist;
                massagistInfo.loading = false;
                return massagist;

                
            }

            onMounted(() => {
                getMassagistInfo(route.params.id);
            })

            return {
                inputs,
                phonesAmount,
                addPhoneField,
                massageInputs,
                massageInputsAdditional,                
                workingTimeFrom,
                workingTimeTill,
                massagistInfo,
                closePhoneField,
                status
            }
        }
    }
</script>

<style lang="scss" scoped>
    .anket-page{
        min-height: 500px;
        h3{
            text-align: center;
        }
        .title{
            margin-bottom: 0.5em;
        }
        .page-block{
            display: flex;
            flex-wrap: wrap;
            width: 95%;
            margin: 0 auto;
            .block{
                width: 100%;
                padding: 0.5em 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .info{
                    margin: 0.5em 0;
                }
                .old-info{
                    .label{
                        margin-right: 1em;
                        padding: 0.5em 0;
                }
                    .icon{
                        cursor: pointer;
                    }
                }
                .new-info{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .input-enter-active{
                        transition: 0.3s ease;
                    }
                    .input-enter-from{
                        opacity: 0;
                    }
                    .input-enter-to{
                        opacity: 1;
                    }
                    .input-leave-active{
                        transition: 0.3s ease;
                    }
                    .input-leave-from{
                        opacity: 1;
                    }
                    .input-leave-to{
                        opacity: 0;
                    }
                    .icon{
                        cursor: pointer;
                    }
                }
            }
        }

        .common-info{           
            border: 1px solid $accent-dark;
            margin: 1em auto;
            padding: 1em;
        }
        .my-clients{            
            padding: 1em;
        }
        .clients-age{
            border: 1px solid $accent-dark;
            margin: 1em auto;
            padding: 1em;
        }
        .my-place{
            
        }
        .your-place{
           
        }
        .massage-type{
            .basic-types{
                h2{
                    margin-bottom: 1em;
                }
            }
            .additional-types{
                h2{
                     margin-bottom: 1em;
                }
            }
        }
        .working-days{
            h2{
                margin-bottom: 1em;
            }
        }
        .action{
            text-align: center;
            padding: 2em;
        }
    }

@media screen and (min-width: 768px) {
    .anket-page{
        .page-block{                
            width: 60%;                
        }
    }
}
@media screen and (min-width: 1024px) {
    .anket-page{
        .page-block{                
            width: 60%;                
        }
    }
}
</style>