export default [
    {
        id: 'aqua',
        label: 'Аква-пенный',
        name: "aqua"
    },
    {
        id: 'body',
        label: 'Боди массаж',
        name: "body"
    },
    {
        id: 'ginecologic',
        label: 'Гинекологический',
        name: "ginecologic"
    },
    {
        id: 'egypt',
        label: 'Древнеегипетский',
        name: "egypt"
    },
    {
        id: 'colon',
        label: 'Колон-массаж',
        name: "colon"
    },
    {
        id: 'correction',
        label: 'Коррекция фигуры',
        name: "correction"
    },
    {
        id: 'crio',
        label: 'Криомассаж',
        name: "crio"
    },
    {
        id: 'fourhands',
        label: 'В четыре руки',
        name: "fourhands"
    },
    {
        id: 'spoons',
        label: 'Массаж ложками',
        name: "spoons"
    },
    {
        id: 'foot',
        label: 'Массаж ногами',
        name: "foot"
    },
    {
        id: 'prostata',
        label: 'Массаж простаты',
        name: "prostata"
    },
    {
        id: 'stop',
        label: 'Массаж стоп',
        name: "stop"
    },
    {
        id: 'wrapping',
        label: 'Обертывание',
        name: "wrapping"
    },
    {
        id: 'tai',
        label: 'Тайский',
        name: "tai"
    },
    {
        id: 'tantrum',
        label: 'Тантрический',
        name: "tantrum"
    },
    {
        id: 'urinal',
        label: 'Урологический',
        name: "urinal"
    },
    {
        id: 'swey',
        label: 'Шведский',
        name: "swey"
    },
    {
        id: 'japan',
        label: 'Японский',
        name: "japan"
    },
]