<template>
    <div class="block name" v-if="city.length > 0">
        <div class="old-info info">
            <span class="label">Город: {{ city }}</span>
            <AppEditIcon @action="editCity" class="icon" v-if="!cityEditing" />                    
            <BaseCloseIcon class="icon" v-if="city.length < 1 || cityEditing" @action="closeEditCity" />
        </div>
        <div class="new-info info" >
            <transition name="input">
            <BaseInput 
                outlined 
                rounded
                label="Город"
                width="320"
                id="city"
                validation="autocomplete"
                autocomplete
                :autocompleteData="cityData.autocompleteData"
                :prefiltredData="cityData.prefiltredData"
                v-if="city.length < 1 || cityEditing" @sendData="getNewCity($event)"/>
            </transition>                    
            <transition name="input">
            <BaseSaveIcon class="icon" v-if="city.length < 1 || cityEditing" @action="sendNewCity" />
            </transition>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
import cities from "@/database/cities.js";
    export default {
        components: {           
            AppEditIcon         
        },
        props: {
            city: {
                type: String,
                default: ""
            }
        },
        setup(props, { emit }){
            const newCity = ref("");
            const cityEditing = ref(false);
            const editCity = () => cityEditing.value = true;
            const closeEditCity = () => cityEditing.value = false;
            const getNewCity = (data) => {
                newCity.value = data;
            };
            const sendNewCity = () => {
                if(newCity.value.length > 0){
                    emit('sendNewCity', { column: 'city', value: newCity.value })
                } else {
                    return false;
                }
            }
            const cityData = {
                autocompleteData: cities,
                prefiltredData: [
                    'Москва',
                    'Санкт-Петербург',
                    'Новосибирск',
                    'Казань'
                ]
            }


            return{
                newCity,
                cityEditing,
                editCity,
                closeEditCity,
                getNewCity,
                sendNewCity,
                cityData
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block{
        width: 100%;
        padding: 0.5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
            margin: 1em 0;
        }
        .old-info{
            .label{
                margin-right: 1em;
                padding: 0.5em 0;
        }
            .icon{
                cursor: pointer;
            }
        }
        .new-info{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .input-enter-active{
                transition: 0.3s ease;
            }
            .input-enter-from{
                opacity: 0;
            }
            .input-enter-to{
                opacity: 1;
            }
            .input-leave-active{
                transition: 0.3s ease;
            }
            .input-leave-from{
                opacity: 1;
            }
            .input-leave-to{
                opacity: 0;
            }
            .icon{
                cursor: pointer;
            }
        }
    }
</style>