<template>
    <div class="page-block about-me">
        <div class="about-massagist">О себе: {{ text }}</div>
        <AppTextArea :options="about" @sendData="getData($event)" :text="text" />
        <AppRegularButton text="сохранить" dark @action="sendNewMassagistAbout" />
        <Warning v-if="warning.isActive" :text="warning.text" />
        <div class="moderation" :class="{ 'approved': approved > 0 }" >{{ moderation }}</div>
    </div>
</template>

<script>
import AppTextArea from "@/components/inputs/app-text-area.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import Warning from "@/components/popups/app-popup-warning";
import { ref, reactive, computed } from "vue";

    export default {
        components: {
            AppTextArea,
            AppRegularButton,
            Warning
        },
        props: {
            text: {
                type: String,
                default: ""
            },
            approved: {
                type: Number,
                default: 0
            }
        },
        setup(props, { emit }){
            const about = {
                label: "О себе",
                id: "about"
            }
            const aboutMassagist = ref("");

            const getData = (data) => {
                aboutMassagist.value = data;
            }

            const sendNewMassagistAbout = () => {
                warning.isActive = true;
                warning.text = "Раздел О себе будет доступен посетителям сайта только после проверки модератором!";
                setTimeout(() => {
                    warning.isActive = false;
                    warning.text = "";
                    emit('sendNewMassagistAboutData', { column: "about_me", value: aboutMassagist.value });
                    emit('updateModeration', { column: "about_me_approved", value: 0 } )
                }, 3000)
            }

            const warning = reactive({
                isActive: false,
                text: ""
            });

            const moderation = computed(() => {
                if(props.approved < 1){
                    return "Информация ещё не проверена модератором и временно не отображается в вашей анкете"
                } else {
                    return "Информация проверена и одобрена модератором. Она доступна посетителям сайта"
                }
            });

            return {
                about,
                getData,
                sendNewMassagistAbout,
                warning,
                moderation
            }
        }
    }
</script>

<style lang="scss" scoped>
    .about-me{
        padding: 1em;
        margin: 1em auto;
        border: 1px solid $accent-dark;
        .about-massagist{
            padding: 0.5em;
        }
        .moderation{
            padding: 1em;
            font-size: 0.8em;
            font-weight: 700;
            color: $error;
        }
        .approved{
            color: $success;
        }
    }
</style>
