<template>
    <div class="my-place">
        <AppCheckInput :options="input" @checked="isChecked($event)" :checked="isMyPlaceChecked"/>
        <div class="my-place-prices" v-if="isMyPlaceChecked">            
                <div class="old-info info">
                    <span class="label">Цена за час: <span v-if="currentPrice > 0">{{ currentPrice }}</span></span>
                    <AppEditIcon @action="editPricePerHour" class="icon" v-if="!pricePerHourEditing && currentPrice > 0" />                    
                    <BaseCloseIcon class="icon" v-if="currentPrice < 1 || pricePerHourEditing" @action="closeEditPricePerHour" />
                </div>
                <div class="new-info info" >
                    <transition name="input">
                        <BaseInput outlined rounded width="320" label="цена за час" id="hourYourPlace" validation="price" @sendData="getNewPricePerHour($event)" v-if="currentPrice < 1 || pricePerHourEditing" /> 
                    </transition>                    
                    <transition name="input">
                        <BaseSaveIcon class="icon" @action="sendNewPricePerHour" v-if="currentPrice < 1 || pricePerHourEditing" />
                    </transition>                
                </div>
                <div class="old-info info">
                    <span class="label">Цена за два часа: <span v-if="currentPricePerTwoHours > 0">{{ currentPricePerTwoHours }}</span></span>
                    <AppEditIcon @action="editPricePerTwoHours" class="icon" v-if="!pricePerTwoHoursEditing && currentPricePerTwoHours > 0" />                    
                    <BaseCloseIcon class="icon" v-if="currentPricePerTwoHours < 1 || pricePerTwoHoursEditing" @action="closeEditPricePerTwoHours" />
                </div>
                <div class="new-info info" >
                    <transition name="input">
                        <BaseInput outlined rounded width="320" label="цена за два часа" id="TwoHoursYourPlace" validation="price" @sendData="getNewPricePerTwoHours($event)" v-if="currentPricePerTwoHours < 1 || pricePerTwoHoursEditing" /> 
                    </transition>                    
                    <transition name="input">
                        <BaseSaveIcon class="icon" @action="sendNewPricePerTwoHours" v-if="currentPricePerTwoHours < 1 || pricePerTwoHoursEditing" />
                    </transition>                
            </div>             
        </div>
    </div>
</template>

<script>
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
import { computed, ref } from "vue";
    export default {
        components: {
            AppCheckInput,
            AppEditIcon
        },
        props: {
            checked: {
                type: Number,
                default: 0
            },
            currentPrice: {
                type: Number,
                default: 0
            },
            currentPricePerTwoHours: {
                type: Number,
                default: 0
            }
        },
        setup(props, { emit }){
            const input = {
                label: "Выезжаю к вам",
                id: "myplace",
                name: "myPlace"
            }
            const checked = ref(false);
            const isChecked = (data) => {
                emit('myPlaceNewInfo', { column: "your_place", value: +data.checked })
            } 
            const isMyPlaceChecked = computed(() => {
                if(props.checked < 1){
                    return false;
                } else return true;
            })


            const pricePerHour = ref(0);
            const pricePerHourEditing = ref(false);
            const editPricePerHour = () => {
                pricePerHourEditing.value = true;
            };
            const closeEditPricePerHour = () => pricePerHourEditing.value = false;
            const getNewPricePerHour = (data) => {
                pricePerHour.value = data;
            };
            const sendNewPricePerHour = () => {
                if(pricePerHour !== null){
                    pricePerHourEditing.value = false;
                    emit('sendNewPricePerHourToDB', { column: 'your_place_per_hour_price', value: pricePerHour.value });
                } else {
                    return false;
                }
            };
            const pricePerTwoHours = ref(0);
            const pricePerTwoHoursEditing = ref(false);
            const editPricePerTwoHours = () => {
                pricePerTwoHoursEditing.value = true;
            };
            const closeEditPricePerTwoHours = () => pricePerTwoHoursEditing.value = false;
            const getNewPricePerTwoHours = (data) => {
                pricePerTwoHours.value = data;
            };
            const sendNewPricePerTwoHours = () => {
                if(pricePerTwoHours !== null){
                    pricePerTwoHoursEditing.value = false;
                    emit('sendNewPricePerTwoHoursToDB', { column: 'your_place_per_two_hours_price', value: pricePerTwoHours.value });
                } else {
                    return false;
                }
            };



            return{
                input,
                isChecked,
                checked,
                isMyPlaceChecked,
                editPricePerHour,
                closeEditPricePerHour,
                getNewPricePerHour,
                pricePerHourEditing,
                pricePerHour,
                sendNewPricePerHour,
                editPricePerTwoHours,
                closeEditPricePerTwoHours,
                getNewPricePerTwoHours,
                pricePerTwoHoursEditing,
                pricePerTwoHours,
                sendNewPricePerTwoHours
            }
        }
       
    }
</script>

<style lang="scss" scoped>
    .my-place{
        padding: 1em;
        margin: 1em auto;
        border: 1px solid $accent-dark;
        width: 100%;
        .my-place-prices{
            display: flex;
            flex-direction: column;
            .old-info{
                width: 100%;
                padding: 1em 0;
                .icon{
                    margin-left: 1em;
                    cursor: pointer;
                }
            }
            .new-info{
                display: flex;
                align-items: center;
                .icon{
                    cursor: pointer;
                    margin-left: 1em;
                }
            }
        }
    }
</style>