export default [
    {
        id: 'anticellulite',
        label: 'Антицеллюлитный',
        name: "anticellulite"
    },
    {
        id: 'apparat',
        label: 'Аппаратный',
        name: "apparat"
    },
    {
        id: 'cans',
        label: 'Баночный',
        name: "cans"
    },
    {
        id: 'eastern',
        label: 'Восточный',
        name: "eastern"
    },
    {
        id: 'classic',
        label: 'Классический',
        name: "classic"
    },
    {
        id: 'cosmetic',
        label: 'Косметический',
        name: "cosmetic"
    },
    {
        id: 'healing',
        label: 'Лечебный',
        name: "healing"
    },
    {
        id: 'headmassage',
        label: 'Массаж головы',
        name: "headmassage"
    },
    {
        id: 'breastmassage',
        label: 'Массаж груди',
        name: "breastmassage"
    },
    {
        id: 'facemassage',
        label: 'Массаж лица',
        name: "facemassage"
    },
    {
        id: 'footmassage',
        label: 'Массаж ног',
        name: "footmassage"
    },
    {
        id: 'handsmassage',
        label: 'Массаж рук',
        name: "handsmassage"
    },
    {
        id: 'backmassage',
        label: 'Массаж спины',
        name: "backmassage"
    },
    {
        id: 'relaxmassage',
        label: 'Расслабляющий',
        name: "relaxmassage"
    },
    {
        id: 'reanimationmassage',
        label: 'Реанимационный',
        name: "reanimationmassage"
    },
    {
        id: 'sportmassage',
        label: 'Спортивный',
        name: "sportmassage"
    },
    {
        id: 'pointingmassage',
        label: 'Точечный',
        name: "pointingmassage"
    },
    {
        id: 'erotic',
        label: 'Эротический',
        name: "erotic"
    },
]