<template>
    <div class="app-text-area">
        <textarea type="textarea" class="basic-input" :id="options.id" @focus="activate" @blur="disactivate" v-model="inputData" maxlength="500" @change="$emit('sendData', $event.target.value)"></textarea>
        <label :for="options.id" class="input-label" :class="{ 'active': active }" >{{ options.label }}</label>
        <AppCloseIcon class="input-clear" @click="clear" />
    </div>
</template>

<script>
import AppCloseIcon from "@/components/icons/app-close-icon.vue";
    export default {
        components: {
            AppCloseIcon
        },
        props: {
            options: {
                label: {
                    type: String,
                    default: ""
                },
                id: {
                    type: String,
                    default: ""
                },
            },
            text: {
                type: String,
                default: ""
            }
        },
        data(){
            return{
                active: false,
                inputData: ""
            }
        },
        methods: {
            activate(){
                if(this.inputData.length > 0){
                    return;
                } else {
                    this.active = true;
                    this.inputData += this.text;

                }
            },
            disactivate(){
                if(this.inputData.length > 0){
                    return;
                } else {
                    this.active = false;

                }
            },
            clear(){
                this.active = false;
                this.inputData = '';
            }
        },
    }
</script>

<style lang="scss" scoped>
    .app-text-area{
        width: 100%;
        height: 250px;
        position: relative;
        margin: 1em 0;
        .basic-input{
            width: 100%;
            height: 100%;
            border: 2px solid $accent;
            border-radius: 0.4em;
            padding: 2em;
            color: $black;
            font-size: 1em;
            font-weight: 700;
            &:focus{
                border: 2px solid $error;
                outline: none;
            }
        }
        .input-label{
            color: $black;
            font-size: 1em;
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 2em;
            background-color: $white;
            transform: translateY(-50%);
            transition: 0.3s ease;
            padding: 0.1em 0.5em;
            border-radius: 0.2em;
        }
        .input-clear{            
            position: absolute;
            top: 50%;
            right: 1em;
            transform: translateY(-50%);
            cursor: pointer;
        }
        .active{
            top: 0;
            font-size: 0.8em;
            color: $error;
            text-transform: lowercase;
        }
    }
</style>