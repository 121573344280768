<template>
    <label class="container"> Мужской
        <input type="radio" name="massagist" id="male" @input="sendNewData($event.target.id)" :checked="isMaleChecked">    
        <span class="checkmark"></span>
    </label>
    <label class="container"> Женский
        <input type="radio" name="massagist" id="female" @input="sendNewData($event.target.id)" :checked="!isMaleChecked">    
        <span class="checkmark"></span>
    </label>
</template>

<script>
    export default {
        props: {
            sex: {
                type: String,
                default: ""
            },
        },
        emits: ['sendNewSex'],
        computed: {
            isMaleChecked(){
                return this.sex === 'male' ? true : false
            }
        },
        methods: {
            sendNewData(data){
                this.$emit('sendNewSex', { column: 'sex', value: data })
            }
        }
    }
</script>

<style lang="scss" scoped>
.container{
    font-size: 1em;
    font-weight: 700;
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
    width: 100%;
    // padding: 1em 0;
    margin: 0 0 2em;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: $white;
}


.container input:checked ~ .checkmark {
    background-color: $white;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $accent-dark;
}


.container input:checked ~ .checkmark:after {
    display: block;
}

</style>