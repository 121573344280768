<template>
    <div class="basic-types">
        <h2>Дополнительные виды массажа</h2>
        <div class="massage-types">
            <div class="checkbox" v-for="input, index in massageInputs" :key="index">
                <AppCheckInput :options="input" @checked="getCheckedData($event)" :checked="isInputChecked(input.id)"/>
            </div>
        </div>
        <AppRegularButton text="сохранить" dark @action="sendNewData" />
    </div>
</template>

<script>
import MassageInputTypes from "@/database/massageInputTypesAdditional.js";
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import { reactive, onUpdated } from 'vue';
    export default {
        components: {
            AppCheckInput,
            AppRegularButton
        },
        props: {
            options: {
                type: String,
                default: ""
            },
        },
        setup(props, { emit }) {
            const massageInputs = MassageInputTypes;

            const massageTypes = reactive([]);

            const getCheckedData = (data) => {
                if(data.checked){
                    const isDataInArray = massageTypes.some(obj => obj.id === data.id); 
                    if(isDataInArray){
                        return;
                    } else {
                        massageTypes.push(data);
                    }
                } 
                else {
                    const isDataInArray = massageTypes.some(obj => obj.id === data.id);
                    if(isDataInArray){
                        massageTypes.forEach((obj, index) => {
                            if(obj.id === data.id){
                                massageTypes.splice(index, 1)
                            }
                        })
                    } else {
                        return;
                    }
                }
            }

            const sendNewData = () => {
                let stringToSend = ""
                massageTypes.forEach(obj => {
                    stringToSend += obj.id + ", ";
                });
                const regEx = new RegExp(",$")
                stringToSend = stringToSend.trim().replace(regEx, "");
                if(stringToSend.length < 1){
                    emit('sendNewMassageTypes', { column: 'additional_massage_types', value: null })
                } else {
                    emit('sendNewMassageTypes', { column: 'additional_massage_types', value: stringToSend })
                }
            }

           const isInputChecked = (input) => {
                if(props.options){
                    if(props.options.match(input)){
                        return true
                    } else return false
                } else {
                    return false
                }
            }

             onUpdated(() => {
                if(props.options){
                    let array = props.options.split(',');
                    array = array.map(el => el.trim())
                    array.forEach(obj => {
                        const isDataInArray = massageTypes.some(el => el.id === obj); 
                        if(isDataInArray){
                            return;
                        } else {
                            if(obj !== 'null'){
                                massageTypes.push({
                                    id: obj,
                                    checked: true
                                })
                            }
                        }
                    })
                }
            })

            return {
                massageInputs,
                getCheckedData,
                sendNewData,
                isInputChecked
            }
        }
    }
</script>

<style lang="scss" scoped>
    .basic-types{
        padding: 1em;
        border: 1px solid $accent-dark;
        width: 100%;
        margin-bottom: 1em;
        h2{
            margin-bottom: 1em;
        }
        .massage-types{
            display: flex;
            flex-wrap: wrap;
            .checkbox{
                width: 200px;
            }
        }
    }
</style>