export default {
    workingTimeFrom: {                
            name: "workingTimeFrom",
            options: [
                {
                    value: "Со скольки",
                    holder: "Со скольки",
                    selected: true,
                    disabled: true
                },
                {
                    value: "00:00",
                    holder: "00:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "01:00",
                    holder: "01:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "02:00",
                    holder: "02:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "03:00",
                    holder: "03:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "04:00",
                    holder: "04:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "05:00",
                    holder: "05:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "06:00",
                    holder: "06:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "07:00",
                    holder: "07:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "08:00",
                    holder: "08:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "09:00",
                    holder: "09:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "10:00",
                    holder: "10:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "11:00",
                    holder: "11:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "12:00",
                    holder: "12:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "13:00",
                    holder: "13:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "14:00",
                    holder: "14:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "15:00",
                    holder: "15:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "16:00",
                    holder: "16:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "17:00",
                    holder: "17:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "18:00",
                    holder: "18:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "19:00",
                    holder: "19:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "20:00",
                    holder: "20:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "21:00",
                    holder: "21:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "22:00",
                    holder: "22:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "23:00",
                    holder: "23:00",
                    selected: false,
                    disabled: false
                },

            ]
    },
    workingTimeTill: {
            name: "workingTimeTill",
            options: [
                {
                    value: "До скольки",
                    holder: "До скольки",
                    selected: true,
                    disabled: true
                },
                {
                    value: "00:00",
                    holder: "00:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "01:00",
                    holder: "01:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "02:00",
                    holder: "02:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "03:00",
                    holder: "03:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "04:00",
                    holder: "04:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "05:00",
                    holder: "05:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "06:00",
                    holder: "06:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "07:00",
                    holder: "07:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "08:00",
                    holder: "08:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "09:00",
                    holder: "09:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "10:00",
                    holder: "10:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "11:00",
                    holder: "11:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "12:00",
                    holder: "12:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "13:00",
                    holder: "13:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "14:00",
                    holder: "14:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "15:00",
                    holder: "15:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "16:00",
                    holder: "16:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "17:00",
                    holder: "17:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "18:00",
                    holder: "18:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "19:00",
                    holder: "19:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "20:00",
                    holder: "20:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "21:00",
                    holder: "21:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "22:00",
                    holder: "22:00",
                    selected: false,
                    disabled: false
                },
                {
                    value: "23:00",
                    holder: "23:00",
                    selected: false,
                    disabled: false
                },

            ]
    }
}