<template>
    <div class="block name">
        <div class="old-info info">
            <span class="label">{{ label }}: {{ phone }}</span>
            <AppEditIcon @action="editphone" class="icon" v-if="!phoneEditing && phone" />                    
            <BaseCloseIcon class="icon" v-if="!phone || phoneEditing" @action="closeEditphone" />
        </div>
        <div class="new-info info" >
            <transition name="input">
            <BaseInput 
                outlined 
                rounded
                label="Телефон"
                width="320"
                id="massagist-phone"
                validation="phone"
                required
                v-if="!phone || phoneEditing" @sendData="getNewphone($event)"/>
            </transition>                    
            <transition name="input">
            <BaseSaveIcon class="icon" v-if="!phone || phoneEditing" @action="sendNewphone" />
            </transition>
        </div>
        <Warning v-if="warning.isActive" :text="warning.text" />
    </div>
</template>

<script>
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
import Warning from "@/components/popups/app-popup-warning";
import { reactive, ref } from 'vue';
    export default {
        components: {           
            AppEditIcon,
            Warning    
        },
        props: {
            phone: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: "Телефон"
            }
        },
        setup(props, { emit }){
            const newphone = ref(null);
            const phoneEditing = ref(false);
            const editphone = () => {
                warning.isActive = true;
                warning.text = "Внимание! Номер телефона используется в качестве логина для входа в личный кабинет. Будьте внимательны, редактируя его!"
                phoneEditing.value = true;
                setTimeout(() => {
                    warning.isActive = false;
                    warning.text = ""
                }, 3000)
            };
            const closeEditphone = () => phoneEditing.value = false;
            const getNewphone = (data) => {
                newphone.value = data;
            };
            const sendNewphone = () => {
                if(newphone){
                    emit('sendNewPhone', { column: 'phone', value: newphone.value })
                } else {
                    return false;
                }
            };

            const warning = reactive({
                isActive: false,
                text: ""
            });



            return{
                newphone,
                phoneEditing,
                editphone,
                closeEditphone,
                getNewphone,
                sendNewphone,
                warning
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block{
        width: 100%;
        padding: 0.5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
            margin: 1em 0;
        }
        .old-info{
            .label{
                margin-right: 1em;
                padding: 0.5em 0;
        }
            .icon{
                cursor: pointer;
            }
        }
        .new-info{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .input-enter-active{
                transition: 0.3s ease;
            }
            .input-enter-from{
                opacity: 0;
            }
            .input-enter-to{
                opacity: 1;
            }
            .input-leave-active{
                transition: 0.3s ease;
            }
            .input-leave-from{
                opacity: 1;
            }
            .input-leave-to{
                opacity: 0;
            }
            .icon{
                cursor: pointer;
            }
        }
    }
</style>