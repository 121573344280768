<template>
    <div class="block name">
        <div class="old-info info">
            <span class="label">Возраст клиента от: {{ clientAge }}</span>
            <AppEditIcon @action="editAge" class="icon" v-if="!ageEditing && age" />                    
            <BaseCloseIcon class="icon" v-if="!age || ageEditing" @action="closeEditAge" />
        </div>
        <div class="new-info info" >
            <transition name="input">
            <BaseInput 
                outlined 
                rounded
                label="Возраст"
                width="320"
                id='client-age-from'
                validation="age"
                required
                v-if="!age || ageEditing" @sendData="getNewAge($event)"/>
            </transition>                    
            <transition name="input">
            <BaseSaveIcon class="icon" v-if="!age || ageEditing" @action="sendNewAge" />
            </transition>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
    export default {
        components: {           
            AppEditIcon         
        },
        props: {
            age: {
                type: Number,
                default: null
            }
        },
        setup(props, { emit }){
            const newAge = ref(null);
            const ageEditing = ref(false);
            const editAge = () => ageEditing.value = true;
            const closeEditAge = () => ageEditing.value = false;
            const getNewAge = (data) => {
                newAge.value = data;
            };
            const sendNewAge = () => {
                if(newAge){
                    emit('sendNewAge', { column: 'client_age_from', value: +newAge.value })
                } else {
                    return false;
                }
            }

            const clientAge = computed(() => {
                if(props.age < 1){
                    return ""
                } else {
                    return props.age
                }
            })

            return{
                newAge,
                ageEditing,
                editAge,
                closeEditAge,
                getNewAge,
                sendNewAge,
                clientAge
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block{
        width: 100%;
        padding: 0.5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
            margin: 1em 0;
        }
        .old-info{
            .label{
                margin-right: 1em;
                padding: 0.5em 0;
        }
            .icon{
                cursor: pointer;
            }
        }
        .new-info{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .input-enter-active{
                transition: 0.3s ease;
            }
            .input-enter-from{
                opacity: 0;
            }
            .input-enter-to{
                opacity: 1;
            }
            .input-leave-active{
                transition: 0.3s ease;
            }
            .input-leave-from{
                opacity: 1;
            }
            .input-leave-to{
                opacity: 0;
            }
            .icon{
                cursor: pointer;
            }
        }
    }
</style>