<template>
     <div class="page-block my-clients">
        <div class="inputs">
            <AppCheckInput :options="inputs.man" @checked="getData($event)" :checked="isInputChecked(inputs.man.id)" />
            <AppCheckInput :options="inputs.woman" @checked="getData($event)" :checked="isInputChecked(inputs.woman.id)" />
            <AppCheckInput :options="inputs.couples" @checked="getData($event)" :checked="isInputChecked(inputs.couples.id)" />
        </div>
        <div class="submit">
            <AppRegularButton text="сохранить" dark @action="sendNewData"/>
        </div>
    </div>
</template>

<script>
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import { reactive, onUpdated } from 'vue';
    export default {
        components: {
            AppCheckInput,
            AppRegularButton
        },
        props: {
            options: {
                type: String,
                default: ""
            },
        },
        setup(props, { emit }){
            const inputs = {
                 man: {
                    label: "Массаж мужчинам",
                    name: "men",
                    id: "Men",

                },
                woman: {
                    label: "Массаж женщинам",
                    name: "women",
                    id: "Women",

                },
                couples: {
                    label: "Массаж парам",
                    name: "couples",
                    id: "Couples",
                },
            }
            const massageToClientType = reactive([]);

            const getData = (data) => {
                if(data.checked){
                    const isDataInArray = massageToClientType.some(obj => obj.id === data.id); 
                    if(isDataInArray){
                        return;
                    } else {
                        massageToClientType.push(data);
                    }
                } 
                else {
                    const isDataInArray = massageToClientType.some(obj => obj.id === data.id);
                    if(isDataInArray){
                        massageToClientType.forEach((obj, index) => {
                            if(obj.id === data.id){
                                massageToClientType.splice(index, 1)
                            }
                        })
                    } else {
                        return;
                    }
                }
                
            }

            const sendNewData = () => {
                let stringToSend = ""
                massageToClientType.forEach(obj => {
                    stringToSend += obj.id + ", ";
                });
                const regEx = new RegExp(",$")
                stringToSend = stringToSend.trim().replace(regEx, "");
                if(stringToSend.length < 1){
                    emit('sendNewClientTypes', { column: 'massage_to', value: null })
                } else {
                    emit('sendNewClientTypes', { column: 'massage_to', value: stringToSend })
                }
            }

              const isInputChecked = (input) => {
                if(props.options){
                    if(props.options.match(input)){
                        return true
                    } else return false
                } else {
                    return false
                }
            }

            onUpdated(() => {
                if(props.options){
                    let array = props.options.split(',');
                    array = array.map(el => el.trim())
                    array.forEach(obj => {
                        const isDataInArray = massageToClientType.some(el => el.id === obj); 
                        if(isDataInArray){
                            return;
                        } else {
                            if(obj !== 'null'){
                                massageToClientType.push({
                                    id: obj,
                                    checked: true
                                })
                            }
                        }
                    })
                }
            })

            return {
                inputs,
                getData,
                sendNewData,
                isInputChecked
            }
        }
    }
</script>

<style lang="scss" scoped>
.my-clients{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid $accent-dark;
}
</style>