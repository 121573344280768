<template> 
    <div class="working-days">
        <h2>Рабочие дни</h2>
        <div class="days">
            <div class="checkbox" v-for="input, index in workingDays" :key="index" >
                <AppCheckInput :options="input" @checked="getCheckedData($event)" :checked="isInputChecked(input.id)" />
            </div>
        </div>
        <AppRegularButton text="сохранить" dark @action="sendNewData" />
    </div>
</template>

<script>
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import { reactive, onUpdated } from 'vue';
    export default {
        components: {
            AppCheckInput,
            AppRegularButton
        },
        props: {
            options: {
                type: String,
                default: ""
            },
        },
        setup(props, { emit }) {
            const workingDays = [
                {
                    id: 'monday',
                    label: 'Пн',
                    name: "monday"
                },
                {
                    id: 'tuesday',
                    label: 'Вт',
                    name: "tuesday"
                },
                {
                    id: 'wednesday',
                    label: 'Ср',
                    name: "wednesday"
                },
                {
                    id: 'thursday',
                    label: 'Чт',
                    name: "thursday"
                },
                {
                    id: 'friday',
                    label: 'Пт',
                    name: "friday"
                },
                {
                    id: 'saturday',
                    label: 'Сб',
                    name: "saturday"
                },
                {
                    id: 'sunday',
                    label: 'Вс',
                    name: "sunday"
                },
            ]

            const wrokingDaysArray = reactive([]);

            const getCheckedData = (data) => {
                if(data.checked){
                    const isDataInArray = wrokingDaysArray.some(obj => obj.id === data.id); 
                    if(isDataInArray){
                        return;
                    } else {
                        wrokingDaysArray.push(data);
                    }
                } 
                else {
                    const isDataInArray = wrokingDaysArray.some(obj => obj.id === data.id);
                    if(isDataInArray){
                        wrokingDaysArray.forEach((obj, index) => {
                            if(obj.id === data.id){
                                wrokingDaysArray.splice(index, 1)
                            }
                        })
                    } else {
                        return;
                    }
                }
            }

            const sendNewData = () => {
                let stringToSend = ""
                wrokingDaysArray.forEach(obj => {
                    stringToSend += obj.id + ", ";
                });
                const regEx = new RegExp(",$")
                stringToSend = stringToSend.trim().replace(regEx, "");
                if(stringToSend.length < 1){
                    emit('sendWorkingDays', { column: 'working_days', value: null })
                } else {
                    emit('sendWorkingDays', { column: 'working_days', value: stringToSend })
                }
            }

            const isInputChecked = (input) => {
                if(props.options){
                    if(props.options.match(input)){
                        return true
                    } else return false
                } else {
                    return false
                }
            }

            onUpdated(() => {
                if(props.options){
                    let array = props.options.split(',');
                    array = array.map(el => el.trim())
                    array.forEach(obj => {
                        const isDataInArray = wrokingDaysArray.some(el => el.id === obj); 
                        if(isDataInArray){
                            return;
                        } else {
                            if(obj !== 'null'){
                                wrokingDaysArray.push({
                                    id: obj,
                                    checked: true
                                })
                            }
                        }
                    })
                }
            })

            return {
                workingDays,
                getCheckedData,
                sendNewData,
                isInputChecked
            }
        }
    }
</script>

<style lang="scss" scoped>
    .working-days{
        padding: 1em;
        border: 1px solid $accent-dark;
        width: 100%;
        margin-bottom: 1em;
        h2{
            margin-bottom: 1em;
        }
        .days{
            display: flex;
            flex-wrap: wrap;
            .checkbox{
                width: 80px;
            }
        }
    }
</style>