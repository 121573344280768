<template>
    <div class="second-number">
        <span v-if="phonesAmount > 0">Дополнительный номер:</span>
        <span>{{ additionalNumber }}</span>
        <AppEditIcon @action="addPhoneField" class="icon" v-if="phonesAmount > 0" />
        <div class="additionalNumbers" v-if="isAdditionalNumFieldOpened">
            <transition name="input">
                <BaseInput validation="phone" outlined rounded width="320" id="phone" @sendData="getAdditionalPhoneNumber($event)" />
            </transition>
            <BaseCloseIcon class="icon" @action="closePhoneField" />
            <BaseSaveIcon class="icon" @action="sendNewphone" />
        </div>
        <div class="add-phone-number" @click="addPhoneField" v-if="!isAdditionalNumFieldOpened && phonesAmount < 1"><span class="icon">+</span><span class="label">Добавить номер телефона</span></div>
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
    export default {
        components: {
            AppEditIcon
        },
        props: {
            additionalNumber: {
                type: String,
                default: null
            }
        },
        setup(props, { emit }){
            const phonesAmount = computed(() => {
                if(props.additionalNumber){
                    return 1
                } else {
                    return 0
                }
            });
            const additionalNumber = ref("");
            const isAdditionalNumFieldOpened = ref(false);
            const addPhoneField = () => {
                isAdditionalNumFieldOpened.value = true;
            }
            const closePhoneField = () => {
                isAdditionalNumFieldOpened.value = false;
            }
            const getAdditionalPhoneNumber = (data) => {
                additionalNumber.value = data;
            }
            const sendNewphone = () => {
                if(additionalNumber.value.length > 0){
                    emit('sendNewAdditionalNumber', { column: "additional_phones", value: additionalNumber.value })
                } else {
                    return;
                }
            }

            return{
                phonesAmount,
                isAdditionalNumFieldOpened,
                addPhoneField,
                closePhoneField,
                getAdditionalPhoneNumber,
                sendNewphone
            }
        }
    }
</script>

<style lang="scss" scoped>
.second-number{
    margin-bottom: 2em;
    span{
        margin-right: 1em;
    }
    .icon{
        cursor: pointer;
    }
    .additionalNumbers{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 1em;
        .icon{
            cursor: pointer;
            margin-left: 2em;
        }
    }
    .add-phone-number{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon{
            color: $accent-dark;
            font-size: 3em;
            font-weight: 700;
            margin-right: 0.5em;
        }
        .label{
            font-weight: 700;
            font-size: 1.3em;
        }
    }
}
        .input-enter-active{
            transition: 0.3s ease;
        }
        .input-enter-from{
            opacity: 0;
        }
        .input-enter-to{
            opacity: 1;
        }
        .input-leave-active{
            transition: 0.3s ease;
        }
        .input-leave-from{
            opacity: 1;
        }
        .input-leave-to{
            opacity: 0;
        }
</style>