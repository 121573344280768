<template>
    <div class="block name" v-if="name.length > 0">
        <div class="old-info info">
            <span class="label" v-if="status === 'massagist'">Имя: {{ name }}</span>
            <span class="label" v-if="status === 'saloon'">Название: {{ name }}</span>
            <AppEditIcon @action="editName" class="icon" v-if="!nameEditing" />                    
            <BaseCloseIcon class="icon" v-if="name.length < 1 || nameEditing" @action="closeEditName" />
        </div>
        <div class="new-info info" >
            <transition name="input">
            <BaseInput 
                outlined 
                rounded
                label="Имя"
                width="320"
                id="massagist-name"
                validation="name"
                v-if="name.length < 1 || nameEditing" @sendData="getNewName($event)"/>
            </transition>                    
            <transition name="input">
            <BaseSaveIcon class="icon" v-if="name.length < 1 || nameEditing" @action="sendNewName" />
            </transition>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
    export default {
        components: {           
            AppEditIcon         
        },
        props: {
            name: {
                type: String,
                default: ""
            }
        },
        setup(props, { emit }){
            const store = useStore();
            const newName = ref("");
            const nameEditing = ref(false);
            const editName = () => nameEditing.value = true;
            const closeEditName = () => nameEditing.value = false;
            const getNewName = (data) => {
                newName.value = data;
            };
            const sendNewName = () => {
                if(newName.length > 0){
                    emit('sendNewName', { column: 'username', value: newName })
                } else {
                    return false;
                }
            }

            const status = computed(() => {
                let userStatus = store.state.user.status;
                return userStatus;
            })


            return{
                newName,
                nameEditing,
                editName,
                closeEditName,
                getNewName,
                sendNewName,
                status
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block{
        width: 100%;
        padding: 0.5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
            margin: 1em 0;
        }
        .old-info{
            .label{
                margin-right: 1em;
                padding: 0.5em 0;
        }
            .icon{
                cursor: pointer;
            }
        }
        .new-info{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .input-enter-active{
                transition: 0.3s ease;
            }
            .input-enter-from{
                opacity: 0;
            }
            .input-enter-to{
                opacity: 1;
            }
            .input-leave-active{
                transition: 0.3s ease;
            }
            .input-leave-from{
                opacity: 1;
            }
            .input-leave-to{
                opacity: 0;
            }
            .icon{
                cursor: pointer;
            }
        }
    }
</style>