<template> 
    <div class="working-time">
        <h2>Рабочее время</h2>
        <div class="time-from">
            <span>с {{ options ? options.substring(0, 5) : "00:00" }}</span>
            <AppSelectInput :select="workingTimeFrom" class="input" @selected="getTimeFrom($event)" />
        </div>
        <div class="time-till">
            <span>до {{ options ? options.substring(6) : "00:00" }}</span>
            <AppSelectInput :select="workingTimeTill" class="input" @selected="getTimeTill($event)" />
        </div>
        <AppRegularButton text="сохранить" dark @action="sendNewData" />
    </div>
</template>

<script>
import AppSelectInput from "@/components/inputs/app-select-input.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import WorkingTime from "@/database/workingTime.js";
import { reactive, onUpdated } from 'vue';
    export default {
        components: {
            AppSelectInput,
            AppRegularButton
        },
        props: {
            options: {
                type: String,
                default: null
            },
        },
        setup(props, { emit }) {
            const workingTimeFrom = WorkingTime.workingTimeFrom;
            const workingTimeTill = WorkingTime.workingTimeTill;

            const workingTime = reactive({
                from: '00:00',
                till: '00:00'
            });

            const getTimeFrom = (data) => {
                workingTime.from = data;
            }
            const getTimeTill = (data) => {
                workingTime.till = data;
            }

            const sendNewData = () => {
                let string = `${workingTime.from}, ${workingTime.till}`;
                emit('sendWorkingTime', { column: 'working_hours', value: string })
            }

            onUpdated(() => {
                if(props.options){
                    let array = props.options.split(',');
                    workingTimeFrom.options.forEach(time => {
                        if(time.value === array[0].trim()){
                            time.selected = true;
                        } else {
                            time.selected = false;
                        }
                    });
                    workingTimeTill.options.forEach(time => {
                        if(time.value === array[1].trim()){
                            time.selected = true;
                        } else {
                            time.selected = false;
                        }
                    })
                }
            })

            return {
                workingTimeFrom,
                workingTimeTill,
                getTimeFrom,
                getTimeTill,
                sendNewData,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .working-time{
        padding: 1em;
        width: 100%;
        border: 1px solid $accent-dark;
        margin-bottom: 1em;
        h2{
            margin-bottom: 1em;
        }
        .input{
            margin: 1em 0;
        }
    }
</style>